@import '../../../../../styles/customMediaQueries.css';

.priorityLinkWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right;
  gap: 8px;
  padding: 0 8px;
}

.priorityLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;
  border-bottom: 0px solid;
  border-radius: 20px;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  background: #EDEDED;
  color: var(--colorBlack);

  flex-shrink: 0;
  height: fit-content;
  padding: 12px 12px 12px 12px;
  margin: auto 0;

  cursor: pointer;

  &:hover {
    color: var(--colorBlack);
    background: #b7b7b7;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: auto 0;
  }
}

.highlight {
  color: var(--colorWhite);
  background: var(--marketplaceColor);
  &:hover {
    color: var(--colorWhite);
    background: var(--marketplaceColorDark);
  }
}

.priorityLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 0px 0;
  text-decoration: inherit;
  text-wrap: nowrap;
}

.icon {
  width: 18px;
  height: 18px;
}