@import '../../../../styles/customMediaQueries.css';

/* Desktop */
.root {
  /* Size */
  width: 100%;
  height: var(--topbarHeightDesktop);

  /* Layout for child components */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 12px 0 0;

  /* fill */
  background-color: var(--colorWhite);

  /* shadows */
  box-shadow: var(--boxShadowLight);

  @media (--viewportLarge) {
    padding: 0 24px 0 0;
  }
}

/* logo */
.logoLink {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);
}
.logoLink:hover {
  border-bottom: 4px solid var(--marketplaceColor);
  border-radius: 0;
}

/* Search */
.searchLink {
  min-width: 320px;
  height: 100%;
  border-left-style: solid;
  border-left-width: 1px;
  border-left-color: var(--colorGrey100);
  margin-right: auto;
}

.spacer {
  min-width: 320px;
  height: 100%;
  margin-right: auto;
}

.search {
  font-weight: var(--fontWeightMedium);
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  display: inline-block;
  margin: 28px 0;
  text-decoration: inherit;

  @media (--viewportMedium) {
    font-weight: var(--fontWeightMedium);
  }
}

.takeAvailableSpace {
  flex-grow: 1;

  /* At 1024px, the translations might need more space than what searchLink otherwise gets. */
  min-width: 220px;
}

.topbarSearchWithLeftPadding {
  padding-left: 24px;
  padding-right: 24px;
  height: var(--topbarHeightDesktop);

  @media (--viewportLarge) {
    padding-left: 34px;
  }
}

/* These is used with Inbox, Sign up, and Log in */
.topbarLink {
  flex-shrink: 0;

  border-bottom: 0px solid;
  border-radius: 20px;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  
  background: #EDEDED;
  color: var(--colorBlack);

  height: fit-content;
  padding: 12px 12px 12px 12px;
  margin: auto 0;

  cursor: pointer;

  &:hover {
    color: var(--colorBlack);
    background: #b7b7b7;
    text-decoration: none;
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin: auto 0;
  }
}

.favouritesIcon {
  width: 20px;
  height: 20px;
}

.otherlinks {
  display: flex;
  flex-direction: row;
  gap: 8px;

  margin: auto 0;
}

.topbarLinkLabel {
  composes: textSmall from global;
  display: inline-block;
  margin: 0 0;
  text-decoration: inherit;
  position: relative;
}

.inboxIcon {
  width: 20px!important;
  height: 20px!important;
}

.notificationDot {
  /* Dimensions */
  width: 7px;
  height: 7px;
  border-radius: 50%;

  /* Position */
  position: absolute;
  top: 0px;
  right: 0px;

  /* Style: red dot */
  background-color: var(--colorFail);

  animation-name: notificationPop;
  animation-duration: 0.1s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;

  animation-timing-function: ease-in;
  opacity: 0;
  transform: scale(0);
}

@keyframes notificationPop {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Profile menu */
.profileMenuLabel {
  border-bottom: 0px solid;
  transition: var(--transitionStyleButton);

  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;
  color: var(--colorGrey700);

  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
  padding: 0 12px 0 12px;

  &:hover {
    border-bottom: 4px solid var(--marketplaceColor);
    border-radius: 0;
    text-decoration: none;
  }

  &:active {
    border-bottom: 0;
  }
}

.profileMenuIsOpen {
  &:hover {
    border-bottom: 0;
  }
}

.avatar {
  margin: 16px 0;
}

.profileMenuContent {
  min-width: 276px;
  padding-top: 20px;
}

/* left animated "border" like hover element */
.menuItemBorder {
  position: absolute;
  top: 2px;
  left: 0px;
  height: calc(100% - 4px);
  width: 0;
  transition: width var(--transitionStyleButton);
}

.menuLink {
  composes: textSmall from global;
  position: relative;
  display: block;

  /* Dimensions */
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 4px 24px;

  /* Layout details */
  color: var(--colorGrey700);
  text-align: left;
  transition: var(--transitionStyleButton);

  &:hover {
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      width: 6px;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    margin: 0;
  }
}

.logoutButton {
  display: inline;

  /* Text size should be inherited */
  text-decoration: none;

  /* Font is specific to this component */
  font-weight: var(--fontWeightMedium);
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0;

  /* Dimensions */
  position: relative;
  width: 100%;
  min-width: 276px;
  margin: 0;
  padding: 20px 24px;

  /* Layout details */
  color: var(--colorGrey300);
  text-align: left;
  white-space: nowrap;
  transition: var(--transitionStyleButton);

  /* Borders */
  border: none;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--colorGrey100);

  &:hover {
    cursor: pointer;
    color: var(--colorBlack);
    text-decoration: none;

    & .menuItemBorder {
      height: 100%;
      width: 6px;
      top: 0;
      background-color: var(--marketplaceColor);
    }
  }

  @media (--viewportMedium) {
    line-height: 16px;
    margin-top: 20px;
    margin-bottom: 0;
  }
}

.currentPage {
  color: var(--colorBlack);
  position: relative;

  & .menuItemBorder {
    width: 6px;
    background-color: var(--colorBlack);
  }

  &:hover {
    & .menuItemBorder {
      background-color: var(--colorBlack);
    }
  }
}

.menuButton {
  composes: buttonDefault from global;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: none;
  border: none;
  color: var(--colorBlack);
  cursor: pointer;

   /* Dimensions */
   width: 100%;
   min-width: 276px;
   margin: 0;
   padding: 4px 24px;
 
   text-align: left;
   transition: var(--transitionStyleButton);
 
   &:focus,
   &:hover {
     color: var(--colorBlack);
     background-color: #eee;
     text-decoration: none;
 
     & .menuItemBorder {
       width: 6px;
       background-color: var(--marketplaceColor);
     }
   }
 
   @media (--viewportMedium) {
     margin: 0;
   }
}

.listingTypeMenu {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: auto 0;

  width: auto;
  height: 100%;
  position: relative;
  outline: none;
}

.listingTypeMenuLabel {
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border-bottom: 0px solid;
    border-radius: 20px;
    transition: var(--transitionStyleButton);
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0;
    background: var(--marketplaceColor);
    color: var(--colorWhite);
    flex-shrink: 0;
    height: -moz-fit-content;
    height: fit-content;
    padding: 12px 12px 12px 12px;
    margin: auto 0;
    cursor: pointer;

    &:hover {
      color: var(--colorWhite);
      background: var(--marketplaceColorDark);
      text-decoration: none;
    }

    @media (--viewportMedium) {
      background: var(--marketplaceColor);
      color: var(--colorWhite);
      line-height: 16px;
      margin: auto 0;
    }
}

.listingTypeMenuContent {
  min-width: 276px;
  padding-top: 20px;
  top: 100%;
}